<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <WarningDialog
          ref="WarningDialogRef"
          :loading="deleting"
          @action="deleteUser"
        />
        <h2 class="secondary--text">Usuarios</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-row class="my-1">
          <v-col md="3">
            <div class="d-flex mb-5">
              <SecretaryForm title="Crear" />
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="secretaries"
          :items-per-page="secretaries.length"
          :loading="loading"
          loading-text="Cargando los datos..."
          no-data-text="Sin datos..."
          item-key="id"
          item-class="text-center"
          disable-sort
          hide-default-footer
          must-sort
        >
          <template slot="item.action" scope="props">
            <div class="d-flex justify-center my-3">
              <SecretaryForm
                class="mx-1"
                title="Editar"
                :secretary="props.item"
              />
              <v-btn
                text
                icon
                small
                
                class="mx-1"
                @click="showWarning(props.item)"
              >
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import SecretaryForm from "@/components/Secretary/SecretaryForm";
import WarningDialog from '../components/WarningDialog.vue';
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Users",
  components: {
    SecretaryForm,
    WarningDialog,
  },
  data() {
    return {
      secretary: {},
      expanded: [],
      singleExpand: false,
      headers: [
        { text: "Nombres", value: "user.firstName", align: "center" },
        { text: "Apellidos", value: "user.lastName", align: "center" },
        { text: "Correo electrónico", value: "user.email", align: "center" },
        { text: "Teléfono", value: "user.phone", align: "center" },
        { text: "Acciones", value: "action", align: "center" },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      loading: false,
      warningSnackBar: false,
      showSnackBar: false,
      snackBar: { text: "", color: "primary", timeout: 5000, icon: "" },
      deleting: false,
    };
  },
  computed: {
    ...mapGetters(["secretaries", "currentUser"]),
  },
  methods: {
    ...mapActions(["fetchUsersByDoctor"]),
    ...mapMutations(["removeSecretary", "setWarning", "setAlert"]),
    showWarning(user) {
      this.secretary = user;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar el usuario ${this.secretary.user.firstName} ${this.secretary.user.lastName}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteUser() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/secretary/${this.secretary.id}`, {
          data: this.secretary,
        });
        this.$refs.WarningDialogRef.show = false;
        this.removeSecretary(this.secretary);
        const alert = {
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          text: res.data.message,
        };
        this.setAlert(alert);
      } catch (error) {
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      } finally {
        this.deleting = false;
      }
    },
  },
  async created() {
    this.loading = true;
    await this.fetchUsersByDoctor({ doctorID: this.currentUser.doctor.id });
    this.loading = false;
  },
};
</script>
